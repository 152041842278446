<template>
  <section>
    <b-modal
      v-model="isCardModalActive"
      width="auto"
      scroll="keep"
      has-modal-card
      trap-focus
    >
      <form ref="tableForm" action="">
        <div class="modal-card" style="width: auto">
          <header class="modal-card-head">
            <p class="title is-size-5-desktop is-size-6-mobile">
              {{ formTitle }}
            </p>
          </header>
          <section class="modal-card-body">
            <b-field label="Jenis Kontak*">
              <b-select
                v-model="active_contact.contact_type_id"
                @input="updateContactName"
                placeholder="Jenis Kontak"
                expanded
                validation-message="Silahkan isi bagian ini"
                required
              >
                <option
                  v-for="contact in contact_type"
                  :key="contact.id"
                  :value="contact.id"
                  >{{ contact.name }}</option
                >
              </b-select>
            </b-field>

            <b-field label="Alamat Email*"
              v-if="this.selectedContact == 'email'"
            >
              <b-input
                :disabled="!active_contact.contact_type_id"
                v-if="this.selectedContact == 'email'"
                type="email"
                @keypress.native="validateInput"
                maxlength=45
                v-model="active_contact.contact_value"
                placeholder="Alamat Email"
                validation-message="Silahkan isi sesuai jenis kontak"
                required
              ></b-input>
            </b-field>
            <b-field label="Nomor*"
              v-show="this.selectedContact == 'number'"
            >
              <b-input
                :disabled="!active_contact.contact_type_id || (active_contact.contact_type_id == 11 && isSameNumber)"
                v-show="this.selectedContact == 'number'"
                @keypress.native="onlyNumber"
                maxlength=13
                minlength=10
                v-model="active_contact.contact_value"
                placeholder="Nomor"
                validation-message="Silahkan isi sesuai jenis kontak"
                required
              ></b-input>
            </b-field>
            <b-field label="Username / Nomor / Alamat*"
              v-show="this.selectedContact == ''"
            >
              <b-input
                :disabled="!active_contact.contact_type_id"
                v-show="this.selectedContact == ''"
                @keypress.native="validateInput"
                maxlength=45
                v-model="active_contact.contact_value"
                placeholder="Username / Nomor / Alamat"
                validation-message="Silahkan isi sesuai jenis kontak"
                required
              ></b-input>
            </b-field>
            <div  v-if="active_contact.contact_type_id == 11 && !manyHP">
              <b-checkbox
                type="is-info"
                v-model="isSameNumber"
                @input="getNumber"
                >Nomor i.saku sama dengan nomor handphone
              </b-checkbox>
            </div>
            <div v-if="active_contact.contact_type_id == 11 && manyHP">
              <b-checkbox
                v-for="item in HPContacts"
                :key="item.id"
                v-model="item.isChecked"
                :native-value="item.contact_value"
                @input="getNumber(item.contact_value)"
                :disabled="isSameNumber && !item.isChecked"
              >
                Nomor i.saku sama dengan nomor handphone {{ item.contact_value }}
              </b-checkbox>
            </div>
            <br v-if="active_contact.contact_type_id == 11">
            <div v-if="active_contact.contact_type_id == 11" :key="active_contact.contact_type_id">
              <a @click="isPosterModal = true"><u>Lihat Poster Perkenalan Terkait i.saku</u></a>
            </div>
          </section>
          <footer class="modal-card-foot is-pulled-right">
            <b-button class="is-pulled-right" @click="closeModal()"
              >Kembali</b-button
            >
            <b-button
              class="is-pulled-right"
              type="is-info"
              @click.prevent="saveContact"
              :loading="isLoading"
              >Simpan</b-button
            >
          </footer>
        </div>
      </form>
    </b-modal>
    <b-modal
      v-model="isPosterModal">
      <p class="image is-2by3">
          <img :src="urlISakuPoster">
      </p>
      <!-- <b-image
          :src="process.env.VUE_APP_DPP_IMAGES + 'poster_isaku'"
          alt="A random image"
          ratio="2by3"
          :rounded="rounded"
      ></b-image> -->
    </b-modal>
    <b-field>
      <b-button class="is-text" icon-left="chevron-left" @click="prev()">
        Sebelumnya
      </b-button>
    </b-field>
    <div class="columns">
      <div class="column">
        <h4 class="title is-size-5-desktop is-size-6-mobile is-pulled-left">
          3 Kontak dan Media Sosial
        </h4>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <b-notification
          type="is-danger is-light"
          aria-close-label="Close notification"
          role="alert"
          :closable="false"
        >
          <p class="is-size-7-mobile">Ketentuan pengisian form:</p>
          <p class="is-size-7-mobile has-icon-left has-text-weight-semibold">
            <b-icon icon="check" size="is-small"></b-icon>
            Nomor Handphone Wajib Diisi
          </p>
          <p class="is-size-7-mobile has-icon-left has-text-weight-semibold">
            <b-icon icon="check" size="is-small"></b-icon>
            Nomor i.saku Wajib Diisi (Jika belum ada bisa dilewati dulu)
            <a :href="linkToGuide" target="_blank">
              <b-icon icon="information-outline" size="is-small"></b-icon>
            </a>
          </p>
          <p class="is-size-7-mobile has-icon-left has-text-weight-semibold">
            <b-icon icon="check" size="is-small"></b-icon>
            Email Wajib Diisi
          </p>
          <p class="is-size-7-mobile has-icon-left has-text-weight-semibold">
            <b-icon icon="check" size="is-small"></b-icon>
            Sosial Media Wajib Diisi - (Instagram, Twitter, Facebook, Dsb)
          </p>
        </b-notification>
      </div>
    </div>
    <div class="columns is-desktop">
      <div class="column is-one-quarter is-offset-9">
        <b-field>
          <b-button
            class="button is-success is-pulled-right"
            @click="add()"
            expanded
          >
            Tambah Data
          </b-button>
        </b-field>
      </div>
    </div>
    <b-table class="box mt-6" :data="contacts" :loading="isLoading">
      <b-table-column
        field="contact_type.name"
        label="Jenis Kontak"
        v-slot="props"
      >
        {{ props.row.contact_type.name }}{{props.row.contact_type.id == 1 && props.index > 0 ? ' 2' : ''}} - {{ props.row.contact_value }}
      </b-table-column>
      <b-table-column field="action" label="Aksi" v-slot="props" centered>
        <b-button
          class="button is-warning is-light"
          @click="editContact(props.row)"
          >Ubah</b-button
        >
      </b-table-column>
    </b-table>
    <b-field>
      <b-button class="button is-info" @click="next()" expanded>
        Simpan & Lanjut
      </b-button>
    </b-field>
  </section>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      isLoading: false,
      isCardModalActive: false,
      selectedContact: '',
      contact_type: [],
      contacts: [],
      newContacts: {
        phone: {
          contact_type_id: 1,
          value: null,
        },
        email: {
          contact_type_id: 2,
          value: null,
        },
      },
      active_contact: {
        id: null,
        contact_type_id: null,
        contact_value: null,
        contact_type: {
          name: null,
        },
      },
      active_contact_default: {
        id: null,
        contact_type_id: null,
        contact_value: null,
        contact_type: {
          name: null,
        },
      },
      edited_index: -1,
      isSameNumber: false,
      isPosterModal: false,
      urlISakuPoster: null,
      manyHP: false,
      HPContacts: [],
      sameNumber: null,
      linkToGuide: process.env.VUE_APP_CAREER_GUIDE_ISAKU,
    };
  },
  created() {
    this.refresh();
    this.urlISakuPoster = process.env.VUE_APP_DPP_IMAGES + '/isaku/poster_isaku.jpeg'
  },
  computed: {
    ...mapGetters({
      applicant: "applicant",
      stepper_position: "stepper_position",
    }),
    formTitle() {
      return this.edited_index === -1
        ? "Tambah Data Kontak"
        : "Ubah Data Kontak";
    },
  },
  methods: {
    ...mapActions({
      setApplicant: "setApplicant",
      setStepperPosition: "setStepperPosition",
    }),
    async next() {
      const foundNomor = this.contacts.some(
        (item) => item.contact_type_id == 1
      );
      const foundEmail = this.contacts.some(
        (item) => item.contact_type_id == 2
      );
      const foundSosmed = this.contacts.some(
        (item) =>
          item.contact_type_id == 3 ||
          item.contact_type_id == 4 ||
          item.contact_type_id == 5 ||
          item.contact_type_id == 6 ||
          item.contact_type_id == 7 ||
          item.contact_type_id == 8 ||
          item.contact_type_id == 9 ||
          item.contact_type_id == 10
      );
      // const foundISaku = this.contacts.some(
      //   (item) => item.contact_type_id == 11
      // );

      if (!foundNomor) {
        this.$buefy.snackbar.open({
          message:
            "Data nomor handphone belum terisi, silahkan mengisi terlebih dahulu",
          type: "is-info",
          position: "is-top",
          actionText: "Tutup",
          indefinite: false,
        });
      } else if (!foundEmail) {
        this.$buefy.snackbar.open({
          message: "Data email belum terisi, silahkan mengisi terlebih dahulu",
          type: "is-info",
          position: "is-top",
          actionText: "Tutup",
          indefinite: false,
        });
      } else if (!foundSosmed) {
        this.$buefy.snackbar.open({
          message:
            "Data sosial media belum terisi, silahkan mengisi terlebih dahulu",
          type: "is-info",
          position: "is-top",
          actionText: "Tutup",
          indefinite: false,
        });
      // } else if (!foundISaku) {
      //   this.$buefy.snackbar.open({
      //     message: "Data i.saku belum terisi, silahkan mengisi terlebih dahulu",
      //     type: "is-info",
      //     position: "is-top",
      //     actionText: "Tutup",
      //     indefinite: false,
      //   });
      } else {
        this.setStepperPosition(3);
      }
    },

    getNumber(value) {
      if (value) {
        if (value != true) {
          if (!this.isSameNumber) {
            this.isSameNumber = true
            this.active_contact.contact_value = value
          } else {
            this.isSameNumber = false
            this.active_contact.contact_value = null
          }
        } else {
          let canGetNumber = false
          let number = null
          this.contacts.forEach(element => {
            if (element.contact_type_id == 1) {
              canGetNumber = true
              number = element.contact_value
            }
          });
          if (!canGetNumber) {
            this.$buefy.snackbar.open({
              message: "Mohon untuk isi Nomor Handphone terlebih dahulu sebelum checklist data ini",
              type: 'is-info',
              position: 'is-top',
              actionText: 'Tutup',
              indefinite: false,
            })
            setTimeout(() => {
              this.isSameNumber = false
            },250)
          } else {
            this.active_contact.contact_value = number
          }
        }
      } else {
        this.isSameNumber = false
        this.active_contact.contact_value = null
      }
    },
    add() {
      this.selectedContact = ''
      this.isCardModalActive = true
		// NOTE refresh state:active_contact and set edited index to -1 (if edited_index = -1, it means new input)
          this.active_contact = JSON.parse(JSON.stringify(this.active_contact_default))
          this.edited_index = -1
      this.isSameNumber = false

      this.HPContacts = []
      let countHP = 0
      this.contacts.forEach( element => {
        if (element.contact_type_id == 1) {
          element.isChecked = null
          this.HPContacts.push(element)
          countHP +=1
        }
      })
      if (countHP > 1)
        this.manyHP = true
    },
    closeModal() {
      this.isCardModalActive = false;
      this.isLoading = false;
      setTimeout(() => {
        this.active_contact = JSON.parse(JSON.stringify(this.active_contact_default));
        this.edited_index = -1;
        this.selectedContact = ''
      }, 300);
    },
    saveContact() {
      // delete leading spaces
      this.active_contact.contact_value = this.active_contact.contact_value ? this.active_contact.contact_value.replace(/^\s*/, "") : null
      this.active_contact.contact_value = this.active_contact.contact_value != null ? this.active_contact.contact_value.length == 1 && (this.active_contact.contact_value == 0 || this.active_contact.contact_value == "0") ? "" : this.active_contact.contact_value : null
      this.active_contact.contact_value = this.active_contact.contact_value != null ? this.active_contact.contact_value.length > 1 && this.active_contact.contact_value.trim().length === 1 && (this.active_contact.contact_value.charAt(0) == 0 || this.active_contact.contact_value.charAt(0) == "0") ? "" : this.active_contact.contact_value : null

      if ((this.active_contact.contact_type_id == 1 || this.active_contact.contact_type_id == 11) && !(/^[0-9]+$/i.test(this.active_contact.contact_value))) {
        this.$buefy.snackbar.open({
            message: "Gagal! Nomor hanya bisa angka",
            type: 'is-info',
            position: 'is-top',
            actionText: 'Tutup',
            indefinite: false,
          })
        return false;
      }
      if (this.active_contact.contact_type_id != 1 && !(/^[A-Za-z0-9\s.@_&-]+$/i.test(this.active_contact.contact_value))) {
        this.$buefy.snackbar.open({
            message: "Gagal! Username atau alamat email hanya bisa angka, alfabet, spasi dan tanda berikut: . @ _ & -",
            type: 'is-info',
            position: 'is-top',
            actionText: 'Tutup',
            indefinite: false,
          })
        return false;
      }
      if (/^(\d)\1*$/.test(this.active_contact.contact_value)) { // check same digits
        this.$buefy.snackbar.open({
            message: "Gagal! Data yang diinput tidak valid",
            type: 'is-info',
            position: 'is-top',
            actionText: 'Tutup',
            indefinite: false,
          })
        return false;
      }

      // lowercase email
      if (this.active_contact.contact_type_id == 2) {
        this.active_contact.contact_value = this.active_contact.contact_value.toLowerCase()
      }

      if (this.$refs.tableForm.checkValidity()) {
        this.isLoading = true;
        this.message = null;
        if (this.edited_index > -1) {
          this.$axios
            .patch(
              `applicants/` +
                this.applicant.id +
                `/contacts/` +
                this.active_contact.id,
              this.active_contact
            )
            .then((response) => {
              this.message = response.data.message;

              if (response.data.status) {
                Object.assign(
                  this.contacts[this.edited_index],
                  this.active_contact
                );
              }
            })
            .catch((e) => {
              this.message = "Error: " + e.response.data.message;
            })
            .finally(() => {
              this.closeModal();
              this.$buefy.snackbar.open({
                message: this.message,
                type: "is-info",
                position: "is-top",
                actionText: "Tutup",
                indefinite: false,
              });
            });
        } else {
          this.$axios
            .post(
              `applicants/` + this.applicant.id + `/contacts`,
              this.active_contact
            )
            .then((response) => {
              this.message = response.data.message;
              if (response.data.status) {
                this.active_contact.id = response.data.data.id;
                this.contacts.push(this.active_contact);
              }
            })
            .catch((e) => {
              this.message = "Error: " + e.response.data.message;
            })
            .finally(() => {
              this.closeModal();
              this.$buefy.snackbar.open({
                message: this.message,
                type: "is-info",
                position: "is-top",
                actionText: "Tutup",
                indefinite: false,
              });
              this.selectedContact = ''
            });
        }
      } else {
        this.message = "Gagal! Data kurang/tidak valid, silahkan cek kembali";
        this.$buefy.snackbar.open({
          message: this.message,
          type: "is-info",
          position: "is-top",
          actionText: "Tutup",
          indefinite: false,
        });
      }
    },
    editContact(contact) {
      this.edited_index = this.contacts.indexOf(contact);
      this.active_contact = JSON.parse(JSON.stringify(contact));
      this.selectedContact = this.assignedContact(this.active_contact.contact_type.name)
      this.isSameNumber = false
      this.isCardModalActive = true;

      this.HPContacts = []
      let countHP = 0
      this.contacts.forEach( element => {
        if (element.contact_type_id == 1) {
          element.isChecked = null
          this.HPContacts.push(element)
          countHP +=1
        }
      })
      if (countHP > 1)
        this.manyHP = true
    },
    refresh() {
      this.isLoading = true;
      this.message = null;
      this.$axios
        .get("applicants/" + this.applicant.id + "/contacts")
        .then((response) => {
          this.message = "Berhasil mengambil data";
          this.contacts = response.data.data;
          for (var i = 0; i < this.contacts.length; i++) {
            if (this.contacts[i].contact_type_id == 1) {
              this.newContacts.phone.value = this.contacts[i].contact_value;
            } else if (this.contacts[i].contact_type_id == 2) {
              this.newContacts.email.value = this.contacts[i].contact_value;
            }
          }
        })
        .catch((e) => {
          this.message = "Error: " + e.response.data.message;
          this.$buefy.snackbar.open({
            message: this.message,
            type: "is-info",
            position: "is-top",
            actionText: "Tutup",
            indefinite: false,
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
      if (!this.contact_type.length) {
        this.$axios
          .get("contact-types")
          .then((response) => {
            this.isLoading = true;

            let isakuData = response.data.data.filter(item => item.id == 11)
            let countForIsaku = 0
            let alreadyAddIsaku = false
            response.data.data.forEach( element => {
              if (countForIsaku == 0 && element.id != 11) {
                this.contact_type.push(element)
              } else if (countForIsaku > 0 && !alreadyAddIsaku) {
                this.contact_type.push(isakuData[0])
                alreadyAddIsaku = true
                countForIsaku = 0
              }

              if (!alreadyAddIsaku)
                countForIsaku += 1
            })
          })
          .catch((e) => {
            this.message = "Error: " + e.response.data.message;
            this.$buefy.snackbar.open({
              message: this.message,
              type: "is-info",
              position: "is-top",
              actionText: "Tutup",
              indefinite: false,
            });
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    updateContactName() {
      this.active_contact.contact_value = null
      for (var i = 0; i < this.contact_type.length; i++) {
        if (this.contact_type[i].id === this.active_contact.contact_type_id) {
          this.active_contact.contact_type.name = this.contact_type[i].name;
          break;
        }
      }
      this.selectedContact = this.assignedContact(this.active_contact.contact_type.name)
      this.isSameNumber = false
    },
    assignedContact(name) {
      if (name == "Email") {
        return "email"
      } else if (name == "Nomor Handphone" || name == "Nomor i.saku") {
        return "number"
      } else {
        return ""
      }
    },
    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (keyCode < 48 || keyCode > 57) {
        // 46 is dot
        $event.preventDefault();
      }
    },
    validateInput($event) {
      let char = String.fromCharCode($event.keyCode);
      if (/^[A-Za-z0-9\s.@_&-]+$/i.test(char)) { 
        return true;
      } else {
        $event.preventDefault();
        return false;
      }
    },
    prev() {
      this.setStepperPosition(1);
    },
  },
};
</script>
